<template>
  <div class="inside-page-content">
    <div class="paper al-center">
      <div class="top-row">
        <div class="top-info">
          <div class="top-name">
            <label class="type">维权办法</label>
          </div>
          <div class="cost">绵阳市知识产权维权援助工作办法</div>
          <div class="cost">2022-10-17</div>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="title-dbI">
        <div class="title-dbI-left"></div>
        <div class="title-dbI-middle"> 工作流程 </div>
        <div class="title-dbI-right"></div>
      </div>
      <img src="../../static/images/flow.png" width="100%"/>

      <div class="title-dbI">
        <div class="title-dbI-left"></div>
        <div class="title-dbI-middle"> 在线申请 </div>
        <div class="title-dbI-right"></div>
      </div>
      <div class="do-online" @click="handleApplyClick">
        <label class="do-online-lab">点击申请</label>
      </div>

      <div class="title-dbI">
        <div class="title-dbI-left"></div>
        <div class="title-dbI-middle"> 经典案例 </div>
        <div class="title-dbI-right"></div>
      </div>
      <!--分割线-->
      <div class="divider-no-mt mt20"></div>
      <div class="divider-no-mt"></div>
      <div class="item" v-for="(item,j) in dataList" :key="item.id" @click="handleDetailClick(item.id)">
        <div class="demoItem">
          <label>{{item.title}}</label>
          <label>{{item.time}}</label>
        </div>
        <div class="divider-no-mt"></div>
      </div>
      <button class="but-more" @click="getList(true)">查看更多</button>
      <div class="divider-no-mt"></div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'safeguard',
  components: {
  },
  data() {
    return {
      dataList:new Array(5),
      current: 0,
      keyword: "",
      page: {
        pageNum: 1,
        pageSize: 5
      },
    }
  },
  computed:{
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(maore) {
      if (maore){
        this.page.pageSize += 5
      }
      this.$api.intellectualProperty.aidList(this.page).then((res) => {
        let data = res.data;
        this.dataList = data.rows
      })
    },
    handleDetailClick(id) {
      this.$router.push({path:"/intellectualProperty/safeguardDetail",  query: {id: id}});
    },
    handleApplyClick() {
      this.$router.push({path:"/intellectualProperty/safeguardApply"});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-info{
    height: 100%;
    flex: 1;
    margin-left: 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    .top-name{
      display: flex;
      align-items: center;
      flex-direction: row;
      .type{
        font-weight: 700;
      }
    }
    .cost{
      font-size: 12px;
    }

  }
}
.divider{
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.divider-no-mt{
  width: 95%;
  margin: 0 auto;
  border-bottom: 1px dashed grey;
}
.title-dbI{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .title-dbI-left{
    height: 15px;
    border-right: 2px solid #00d9ff;
    margin-right: 10px;
  }
  .title-dbI-middle{
    padding: 0 20px;
    height: 30px;
    border-left: 3px solid blue;
    border-right: 3px solid blue;
  }
  .title-dbI-right{
    height: 15px;
    border-left: 2px solid #00d9ff;
    margin-left: 10px;
  }
}
.do-online{
  width: 100%;
  height: 78px;
  margin-top: 20px;
  background-image: url("../../static/images/830BluBack.png");
  background-size: cover;
  margin-bottom: 20px;
  color: blue;
  display:flex;
  cursor: pointer;
  align-items:center;
  .do-online-lab{
    margin-left: 50px;
  }
}
.demoItem{
  width: 95%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}
.mt20{
  margin-top: 20px;
}
.but-more{
  margin: 20px auto;
  font-size: 14px;
  padding: 5px 10px;
  color: blue;
  border: 1px solid blue;
  cursor: pointer;
}
.al-center{
  text-align: center;
}
</style>
